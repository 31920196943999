import Hero from "../components/Hero";
import BookCar from "../components/BookCar";
import PlanTrip from "../components/PlanTrip";
import PickCar from "../components/PickCar";
import Banner from "../components/Banner";
import Bannerz from "../components/clientbanner";
import ChooseUs from "../components/ChooseUs";
import Testimonials from "../components/Testimonials";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
//import Models from "./Models";
import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";

function Home() {


  const [showDelayedComponents, setShowDelayedComponents] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDelayedComponents(true);
    }, 3500);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);



  return (
    <>
      <Helmet>
        <title>
        Dell's Rent A Car Eleuthera | Hatchet Bay Car Rental
        </title>

        <meta
          name="description"
          content="Discover the best car rental deals with Dell's Car Rental, offering top-quality car rental services in Hatchet Bay, Eleuthera, The Bahamas. Whether you're exploring local attractions or need reliable transport from North Eleuthera Airport, Dell's Car Rental ensures an unmatched experience. Book your perfect ride today!"
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      <Hero />
      <PickCar />
      
     
      <ChooseUs />
      
      {showDelayedComponents && (
         <>

      <Bannerz/>
      <Testimonials />
      <Banner />
      <Faq />
      </>
    )}

      <Footer />
    </>
  );
}

export default Home;
