import Img2 from "../images/testimonials/pfp1.jpeg";
import Img3 from "../images/testimonials/pfp2.jpeg";

function Testimonials() {
  return (
    <>
      <section className="testimonials-section">
        <div className="container">
          <div className="testimonials-content">
            <div className="testimonials-content__title">
              <h4>Reviewed by People</h4>
              <h2>Client's Testimonials</h2>
              <p>
              Discover the positive impact through client testimonials. Our reliable and affordable car rentals in Hatchet Bay, Eleuthera, Bahamas have earned rave reviews. Join satisfied customers for a seamless and memorable travel experience. Choose Us for your next journey.
              </p>
            </div>

            <div className="all-testimonials">
              <div className="all-testimonials__box">
                <span className="quotes-icon">
                  <i className="fa-solid fa-quote-right"></i>
                </span>
                <p>
                "Best place in Eleuthera Bahamas to rent a car. Very affordable prices, friendly husband and wife team and cars are always functioning properly! Would definitely recommend! "
                </p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
                    <img src={Img2} alt="user_img" />
                    <span>
                    <h4>Riccara Dean</h4>
                    
                    </span>
                  </div>
                </div>
              </div>

              <div className="all-testimonials__box box-2">
                <span className="quotes-icon">
                  <i className="fa-solid fa-quote-right"></i>
                </span>
                <p>
                  "Wonderful Family that works together to make your "Memories" UNFORGETTABLE!!
Nice Vehicles
Best Tours Around
Will complete your "Bucket List"!!!!!!
Trustworthy
Reliable"
                </p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
              <img src={Img3} alt="user_img" />
                    <span>
                    <h4>Becky Harris</h4>
                      
                    </span>
                  </div>
                </div>
              </div>



              
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonials;
