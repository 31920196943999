
import BgShape from "../images/hero/bookbg1.jpg";
function Banner() {


  const bgImageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  };


  return (
    <>
      <section
        className="banner-section"
        style={{
         // backgroundColor: "#00b712",
          //backgroundImage: "linear-gradient(275deg, #00690a 0%, #3a9e0e 79%)"
          position: 'relative'
        }}
      >
  <img src={BgShape} alt="Background Shape" style={bgImageStyle} />

        <div className="container">
          <div className="row">
            <div className="col-12" style={{ height: "220px" }}>
              <div className="banner-content">
                <div className="banner-content__text" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.69)' , width:'100%'
              }}  >
                  <h2>Best Car Rental In Eleuthera, Bahamas!</h2>
                  
                </div>
              </div>
            </div>

            <div className="col-12" style={{ height: "270px" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3187.294210579261!2d-76.4820942!3d25.3480218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89254baf2e36c1d1%3A0x21f812838e19c27f!2sDell&#39;s%20Rental%20Car!5e0!3m2!1sen!2sus!4v1694754734752!5m2!1sen!2sus"
                width="100%"
                height="100%"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="eager"
              ></iframe>       
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
