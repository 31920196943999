import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import Testimonials from "../components/Testimonials";
import { Helmet } from "react-helmet";

function TestimonialsPage() {


  return (
    <>

        <Helmet>
        <title>
        Testimonials - Dell's Rent A Car Hatchet Bay
        </title>

        <meta
          name="description"
          content="Read what our satisfied customers have to say about their experience with Dell's Car Rental in Hatchet Bay, Eleuthera, The Bahamas. From seamless bookings to reliable vehicles, our clients share their stories of exceptional service and memorable travels. Discover why Dell's Car Rental is the trusted choice for car rentals in Eleuthera"
        />
        {/* Add more meta tags as needed */}
      </Helmet>


      <section className="testimonial-page">
        <HeroPages name="Testimonials" />
        <Testimonials />
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Book a car by getting in touch with us</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3 style={{color:' #87CEEB'}}>+1242-551-0538</h3>  
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default TestimonialsPage;
