

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage'
import 'firebase/compat/firestore';

const firebaseConfig = {

    apiKey: "AIzaSyAQgU8VRqa5HKr2xSodtzbPNERHCrcpYfw",

  authDomain: "dells-car-rental.firebaseapp.com",

  projectId: "dells-car-rental",

  storageBucket: "dells-car-rental.appspot.com",

  messagingSenderId: "853188618216",

  appId: "1:853188618216:web:c4d013b51c0d3d932ae65d"
  
  };
  
  
  
let app;

if (firebase.apps.length === 0) {
    app = firebase.initializeApp(firebaseConfig)
} else {
    app = firebase.app();
}

const db = app.firestore();


export {db} 