
function Footer() {
  function handleSubscribe() {
    alert("Thanks for subscribing!!!");
  }

  return (
    <>
      <footer style={{height:'120px', marginTop:'-90px'}}>
        <div className="container" >
          <div className="footer-content">
            <ul className="footer-content__2">
              <li>Contact Us</li>

              <li style={{ fontSize: "14px", fontWeight: "bold" }}>
                <a href="tel:+1242-551-0538">&#x260E; +1242-551-0538</a>
              </li>

             
              <li style={{ fontSize: "14px", fontWeight: "bold" }}>
                <a href="tel:+1242-815-9263">&#x260E;  +1242-815-9263
                </a>
              </li>


              <li style={{ fontSize: "14px", fontWeight: "bold" }}>
                <a
                  href="mailto: 
                 dellsrentacar@gmail.com"
                >
                  &#9993; dellsrentacar@gmail.com
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://maps.app.goo.gl/HrGmUDEGgfbjReBz6"
                  // href="https://jephunneh.com/"
                >
                  &#128205; Hatchet Bay, Eleuthera, The Bahamas
                </a>
              </li>

             
            </ul>

            <ul className="footer-content__1">
              <li>
                <span style={{ color: "blue" }}>Dell's Rent A Car</span>
               
              </li>

              <li style={{ fontSize: "13px" }}>
                We offer a range of vehicles for car rental in Eleuthera,  Bahamas.
                To rent a car in Eleuthera, we're the best choice.
              </li>

              <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://dev2code.com"
                >
                  <span>Website By:</span>
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://web4business.tech"
                >
                  <span style={{ color: "blue" }}>Qasim</span>
                </a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Company</li>
              <li>
                <a href="https://maps.app.goo.gl/HrGmUDEGgfbjReBz6">
                  Our Location
                </a>
              </li>
              <li>
                <a href="/">Careers</a>
              </li>
              <li>
                <a href="/">Mobile</a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://admindellscarrental.netlify.app"
                  //
                >
                  Admin &#128187;
                </a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Working Hours</li>
              <li>Mon - Sat: 8:00AM - 7:00PM</li>
              <li>Sun: Open</li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
