import { Link } from "react-router-dom";
import BgShape from "../images/hero/hero-bg.png";
import HeroCar from "../images/hero/main-car.png";
import { useEffect, useState } from "react";
import whatsapp from "../images/logo/whatsapp.png";
import facebook from "../images/logo/fb.png";


function Hero() {
  const [goUp, setGoUp] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const bookBtn = () => {
    document.querySelector('#booking-section').scrollIntoView({ behavior: 'smooth' });
  };

  const openWhatsApp = () => {
    window.open('https://wa.me/12425510538', '_blank');  
  };

  const openfacebook = () => {
    window.open('https://www.facebook.com/CYPRUSCARRENTALS.NET', '_blank'); 
   };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.pageYOffset > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('scroll', onPageScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', onPageScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const styles = {
    btn: {
      backgroundColor: '#182b3a',
      backgroundImage: 'linear-gradient(315deg, #182b3a 0%, #20a4f3 74%)',
      transition: 'box-shadow 0.3s ease-in-out',
      fontSize: '18px',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '16px 20px',
      margin: '5px', // add margin for spacing between buttons
      borderColor:'blue',
      borderRadius: '8px',
    },
  };



  const [slideIn, setSlideIn] = useState(false);

  useEffect(() => {
    // Trigger the slide-in animation after a short delay
    const delay = setTimeout(() => {
      setSlideIn(true);
    }, 850); // Adjust the delay as needed

    // Clear the timeout on component unmount to avoid memory leaks
    return () => clearTimeout(delay);
  }, []);

  const [windowWidths, setWindowWidths] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidths(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const slideAnimation = {
    zIndex: 2,
    position: 'absolute',
    right: slideIn ? 54 : '-100%',
    transition: 'right 2.0s ease-in-out',
    width: '90%', // Set the maximum width to 100%
    height: 'auto', // Maintain the aspect ratio
   // maxWidth: windowWidths < 500 ? '85%' : '62%', // Conditionally set maxWidth based on screen width
   maxWidth: windowWidths < 500 ? '80%' : '40%',
    marginTop: '5rem',
  };


  return (
    <section id="home" className="hero-section">
      <div className="container" style={{ position: 'relative' }}>
      <img
        style={{
          position: 'absolute',
          //top: -42,
          top: 22,
          right: 0,
          zIndex: 1,
          height: '90%',
          width:'100%'
        }}
        src={BgShape}
        alt="bg-shape"
      />
      <div className="hero-content" style={{ position: 'relative', zIndex: 2 }}>
        <div className="hero-content__text">
        <div style={{margin:'14px'}}></div>
          <h1 style ={{backgroundColor: 'rgba(255, 255, 255, 0.5)', textAlign:'center'}}>
        Best Car Rental <span style={{ color: '#003d73'  }}> Experience  </span>  
          </h1>
{/* */}
          <div style={{ height: '110px', width:'90%' }}>
{/* 
            {windowWidth >= 1100 ? (
              <div style={styles.container}>
                <p style={{ color:'black' ,backgroundColor: 'rgba(255, 255, 255, 0.6)', borderRadius:'45px'}}>
                Experience the car of your dreams with GAK Car Rental. Enjoy unparalleled prices, unlimited mileage.
                </p>
              </div>
            ) : null} */}

          </div>

          <div className="hero-content__text__btns">
            <Link onClick={bookBtn} className="hero-content__text__btns__book-ride" to="/" style={styles.btn}>
              Book Car
            </Link>
            <a
              style={styles.btn}
            href="https://maps.app.goo.gl/HrGmUDEGgfbjReBz6"
              target="_blank"
              rel="noopener noreferrer"
              className="hero-content__text__btns__book-ride"
            >
              Location
            </a>
            <Link className="hero-content__text__btns__book-ride" to="/contact" style={styles.btn}>
              Contact Us <i className="fa-solid fa-angle-right"></i>
            </Link>
          </div>
        </div>
        <img src={HeroCar} alt="car-img" style={slideAnimation} />
      </div>
    </div>

      {/* WhatsApp button */}


      <div
        onClick={openWhatsApp}
        className="whatsapp-btn"
        style={{
          position: 'fixed',
          bottom: '30px',
          left: '1rem',
          zIndex: '1000',
        }}
      >
        <img src={whatsapp} alt="Whatsapp-img" style={{ height: 65, width: 65 }} />
      </div>


{/* 
      <div
        onClick={openfacebook}
        className="whatsapp-btn"
        style={{
          position: 'fixed',
          bottom: '35px',
          left: '1rem',
          zIndex: '1000', 
        }}
      >
        <img src={facebook} alt="Facebook-img" style={{ height: 50, width: 50, borderRadius:'7px' }} />
      </div>
      */}

      {/* Scroll to top button */}
      <div style={{ backgroundColor:'#182b3a',   backgroundImage: 'linear-gradient(315deg, #182b3a 0%, #20a4f3 74%)'}} onClick={scrollToTop} className={`scroll-up ${goUp ? 'show-scroll' : ''}`}>
        <p style={{ fontSize: 22 }}>&#9650;</p>
      </div>
    </section>
  );
}

export default Hero;

const styles = {
  container: {
   // height: '110px',
    display: 'block', // Ensure it's a block element
   
  },
  // Media query to hide the container when the screen width is less than 500px
  '@media (max-width: 800px)': {
    container: {
      display: 'none',
      
    },
  },
};